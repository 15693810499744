import { Component, ElementRef, HostListener, Inject, NgZone, OnInit, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable, debounceTime, fromEvent } from "rxjs";


@Component({
  selector: 'roaming-terms-and-conditions',
  templateUrl: './roaming-terms-and-conditions.component.html',
  styleUrls: ['./roaming-terms-and-conditions.component.scss']
})
export class RoamingTermsAndConditionsComponent implements OnInit {

  url: string = 'assets/terms_and_conditions/current/Terms_and_Conditions.html';
  scrollBottom = false;

  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  scroll$: any = null;
  constructor(public dialogRef: MatDialogRef<RoamingTermsAndConditionsComponent>, @Inject(NgZone) private zone: NgZone) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.iframe.nativeElement.setAttribute('src', this.url);
  }

  close(accepted = false) {
    this.dialogRef.close(accepted);
  }



  onFrameLoad() {

    const iframe = this.iframe.nativeElement;
    const hasVScrollbar = iframe.contentDocument.documentElement.scrollHeight > iframe.clientHeight;
    this.scrollBottom = !hasVScrollbar;
    this.scroll$ = fromEvent(this.iframe.nativeElement.contentWindow, 'scroll')
      .subscribe((evt: any) => {
        this.onScroll();
      });

  }

  onScroll() {
    const iframeWindow = this.iframe.nativeElement.contentWindow;
    const iframeDocument = this.iframe.nativeElement.contentDocument;
    const scrollHeight = iframeDocument.documentElement.scrollHeight;
    if ((iframeWindow.scrollY + iframeWindow.innerHeight) >= scrollHeight) {
      this.zone.run(() => {
        this.scrollBottom = true;
      });
    }
  }

  NgOnDestroy() {
    this.scroll$.unsubscribe();
  }

}
