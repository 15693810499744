import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { LegacyFloatLabelType } from '@angular/material/legacy-form-field';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RolesService } from 'src/app/services/roles/roles.service';
import {
  CustomWS,
  WebsocketService
} from 'src/app/services/websocket/websocket.service';
import { UserType } from 'src/app/user-roles';

type DisplayFunction = (item: any) => string;

@Component({
  selector: 'sc-socket-search',
  templateUrl: './socket-search.component.html',
  styleUrls: ['./socket-search.component.scss']
})
export class SocketSearchComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('autoComplete', { read: MatAutocompleteTrigger })
  auto: MatAutocompleteTrigger;

  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @Output() clear: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  @Input() displayFn: DisplayFunction;
  @Input() socketURL: string;
  @Input() errorMessage: string;
  @Input() placeholder: string;
  @Input() dropdownLimit: number = 4;
  @Input() matFormFieldClass?: string = '';
  @Input() selectedItem: any;

  @Input() inputRequired: boolean = false;
  @Input() floatingLabel: LegacyFloatLabelType = 'never';

  searchInputCtrl: FormControl = new FormControl('');
  searchSocket: CustomWS;
  socketRetries: number = 0;
  searchInitiated: boolean = false;
  userType = UserType;
  searchItems: any[] = [];
  isAdmin: boolean = this.rolesService.hasHighlevelAccess(this.userType.Admin);

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  constructor(
    private ws: WebsocketService,
    private snackBar: MatSnackBar,
    private rolesService: RolesService,
    private translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedItem?.currentValue) {
      this.searchInputCtrl.patchValue(
        this.displayFn(changes?.selectedItem?.currentValue)
      );
    }
  }

  ngOnInit(): void {}

  initSocket(event: any) {
    if (
      event.relatedTarget &&
      ['MAT-OPTION', 'MAT-SELECT'].includes(event.relatedTarget?.tagName)
    )
      return;
    if (this.searchSocket) return;
    this.searchSocket = this.ws.init(this.socketURL); //Init companies ws

    this.searchSocket.webSocket.subscribe({
      next: (res) => {
        if (res instanceof Array) {
          this.searchItems = res;
          this.results.emit(this.searchItems);
        }
      },
      error: (err: any) => {
        if (this.socketRetries < 3) {
          this.socketRetries++;
          this.initSocket(event);
        } else {
          this.socketRetries = 0;
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG'),
            this.translate.instant('CLOSE')
          );
        }
      }
    });

    this.searchSocket.onOpen.then((open) => {
      this.searchInitiated = true;
      this.onSearch(null);
    });
  }

  onSearch(e) {
    if (e && e.key === 'Enter') {
      this.auto.closePanel();
      this.search.emit(this.searchInputCtrl.value);
    } else {
      let searchVal = this.searchInputCtrl.value;
      if (typeof searchVal === 'string') {
        let search = {
          Text: searchVal ? searchVal.trim() : '',
          Nb: this.dropdownLimit
        };
        if (this.searchInitiated && this.searchSocket)
          this.searchSocket.webSocket.next(search);
      }
    }
  }

  selectItem(e) {
    if (typeof e.option.value === 'string') {
      this.onSearch({ key: 'Enter' });
    } else {
      let item: any = e.option.value;
      this.itemSelected.emit(item);
    }
  }

  clearSocket() {
    if (this.searchSocket) {
      this.searchInitiated = false;
      this.searchSocket.webSocket.complete();
      this.searchSocket = null;
    }
  }

  clearSearch() {
    this.searchInputCtrl.patchValue('');
    this.auto.closePanel();
    this.clear.emit(null);
  }

  ngOnDestroy() {
    if (this.search) {
      this.search = null;
      this.clearSocket();
    }
  }
}
