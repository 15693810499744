import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ComponentCriteriaTypes, ConfigVariable, Ocpp201Components, Ocpp201Variables, OcppVersionEnum } from "src/app/lib/commands";

@Component({
  selector: 'sc-ocpp-config-selector',
  templateUrl: './ocpp-config-selector.component.html',
  styleUrls: ['./ocpp-config-selector.component.scss']
})
export class OCPPConfigSelectorComponent implements OnInit {
  title: string = '';
  action: string = '';
  hideControllerInput = false;
  hideVariableInput = false;
  hideValueInput = false;
  hideAttributeTypeInput = false;
  hideInstanceInput = false;
  hideESVEIDInput = false;
  showComponentCriteria = false;
  ocppVersion: OcppVersionEnum = OcppVersionEnum.VERSION_16_JSON;
  ocppEnum = OcppVersionEnum


  configForm: FormGroup;
  Configurations: FormArray;
  componentsList = Ocpp201Components;
  variablesList = Ocpp201Variables;
  ComponentCriteriaList = ComponentCriteriaTypes;


  constructor(public dialogRef: MatDialogRef<OCPPConfigSelectorComponent>) { }

  ngOnInit() {
    this.configForm = new FormGroup({
      ComponentCriteria: new FormControl(),
      Configurations: new FormArray([this.createConfigFormItem()])
    })
    this.Configurations = this.configForm.get('Configurations') as FormArray;
  }

  createConfigFormItem() {
    if (this.ocppVersion === OcppVersionEnum.VERSION_201_JSON) {
      const requiredValidators = [Validators.required];
      const valueValidators = this.hideValueInput ? [] : requiredValidators;
      const controllerValidators = this.hideControllerInput ? [] : requiredValidators;
      return new FormGroup({
        Controller: new FormControl('', controllerValidators),
        Key: new FormControl('', [Validators.required]),
        Value: new FormControl('', valueValidators),
        AttributeType: new FormControl(''),
        Instance: new FormControl(''),
        EVSEID: new FormControl(''),
      });
    } else {
      const requiredValidators = [Validators.required];
      const valueValidators = this.hideValueInput ? [] : requiredValidators;
      return new FormGroup({
        Key: new FormControl('', [Validators.required]),
        Value: new FormControl('', valueValidators),
      });
    }
  }

  filterComponents(event) {
    const searchText = (event.target.value || "").toLowerCase();
    if (searchText) {
      this.componentsList = [...Ocpp201Components].slice()
        .filter((item) => item.toLowerCase().includes(searchText))
    } else {
      this.componentsList = [...Ocpp201Components].slice()
    }
  }

  filteVariable(event) {
    const searchText = (event.target.value || "").toLowerCase();
    if (searchText) {
      this.variablesList = [...Ocpp201Variables].slice()
        .filter((item) => item.toLowerCase().includes(searchText))
    } else {
      this.variablesList = [...Ocpp201Variables].slice()
    }
  }

  addComponentValue() {
    // this.componentValueList.push({ Controller: '', Key: '' });
    // this.emitChanges();
    this.Configurations.push(this.createConfigFormItem());
  }

  deleteComponentValue(index: number) {
    // this.componentValueList.splice(index, 1);
    // this.emitChanges();
    this.Configurations.removeAt(index);
  }



  handleOnSubmit(event) {
    event.preventDefault();
    if (this.configForm.valid) {
      const values = this.ocppVersion === OcppVersionEnum.VERSION_201_JSON ? this.configForm.value.Configurations.map(((item) => {
        const config: ConfigVariable = {};
        if (!this.hideControllerInput) config.Controller = item.Controller;
        if (!this.hideVariableInput) config.Key = item.Key;
        if (!this.hideValueInput) config.Value = item.Value;
        if (!this.hideAttributeTypeInput) config.AttributeType = item.AttributeType || '';
        if (!this.hideInstanceInput) config.Instance = item.Instance;
        if (!this.hideESVEIDInput) config.EVSEID = item.EVSEID;
        return config;
      })) : this.configForm.value.Configurations.map(((item) => {
        const config: ConfigVariable = {};
        if (!this.hideVariableInput) config.Key = item.Key;
        if (!this.hideValueInput) config.Value = item.Value;
        return config as ConfigVariable
      }));

      this.dialogRef.close({ Configurations: values, ComponentCriteria: this.configForm.value.ComponentCriteria })
    } else {
      console.log(this.configForm)
    }
  }

  close() {
    this.dialogRef.close();
  }
}
