import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserType } from 'src/app/user-roles';
import { RolesService } from '../../roles/roles.service';

@Component({
  selector: 'sc-add-owner',
  templateUrl: './add-owner.component.html',
  styleUrls: ['./add-owner.component.scss']
})
export class AddOwnerComponent {
  emailCtrl = new FormControl('', [Validators.required, Validators.email]);
  authenticationCtrl = new FormControl(false);
  subscriptionCtrl = new FormControl(true);
  semiRegisterCtrl = new FormControl(true);
  loading: boolean = false;
  auth: boolean = false;
  subscription: boolean = true;
  several: boolean = false;

  userType = UserType;

  constructor(
    public dialogRef: MatDialogRef<AddOwnerComponent>,
    private rolesService: RolesService
  ) {}

  ngOnInit() {
    this.authenticationCtrl.patchValue(this.auth);
    this.subscriptionCtrl.patchValue(this.subscription);
  }
  addOwner() {
    if (this.emailCtrl.invalid && !this.several) return;

    this.dialogRef.close({
      email: this.emailCtrl.value,
      authentication: this.authenticationCtrl.value,
      IsCustomerPayingForSubscription: this.subscriptionCtrl.value,
      SemiRegisterIfCustomerNotFound: this.semiRegisterCtrl.value
    });
  }
}
