/* NB! Not to be included in services.ts */

/* Handles logging in/out */
import { Injectable } from '@angular/core';
//import { tokenNotExpired, JwtHelper } from 'angular2-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';

import { Router } from '@angular/router';
import { StyleService } from '../style/style.service';
import { TokenService } from './token/token.service';
import { GlobalFunctions } from '../global-functions/global-functions.service';
import { ClearService } from '../clear/clear.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { TermsAndConditionsComponent } from 'src/app/components/terms-and-conditions/terms-and-conditions.component';
import { WhitelabelService } from '../whitelabel/whitelabel.service';
import { UserService } from 'src/app/lib';
import { TranslateService } from '@ngx-translate/core';
import { signOut } from "aws-amplify/auth";
import { DialogService } from '../dialog/dialog.service';

const helper = new JwtHelperService();
@Injectable()
export class AuthService {
  redirectTo: string;
  constructor(
    private _http: HttpInterceptorService,
    private router: Router,
    private userService: UserService,
    private styleService: StyleService,
    private tokenService: TokenService,
    private gf: GlobalFunctions,
    //private jwtHelper: JwtHelper,
    private clearService: ClearService,
    private dialog: MatDialog,
    private whitelabelService: WhitelabelService,
    private translate: TranslateService,
    private dialogService: DialogService
  ) { }

  login(user, dashboard = true): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!user) {
        reject(this.translate.instant('PROVIDE_USERNAME_AND_PASSWORD'));
      } else if (!user.Username) {
        reject(this.translate.instant('PROVIDE_USERNAME'));
      } else if (!user.Password) {
        reject(this.translate.instant('PROVIDE_PASSWORD'));
      } else {
        let appID = this.gf.guid4();
        user = {
          Email: user.Username.toLowerCase(),
          Password: user.Password,
          isDashboard: dashboard,
          appID: appID,
          appToken: this.whitelabelService.whitelabelID
        };
        this._http.postUnsecure('Users/Authenticate', user).subscribe({
          next: (res) => {
            if (
              res &&
              res.accessToken &&
              res.rToken &&
              res.customer &&
              res.user
            ) {
              if (res.MustConfirmCompanyInformation) {
                this.redirectTo = '/billing';
              }
              this.init(res, appID);

              if (res.TermsAndConditionToAcceptForAStationOwner) {
                let dialogRef: MatDialogRef<TermsAndConditionsComponent>;
                dialogRef = this.dialog.open(TermsAndConditionsComponent, {
                  disableClose: true,
                  autoFocus: false
                });
                dialogRef.componentInstance.termsURL =
                  'https://f.hubspotusercontent40.net/hubfs/2283248/Content/SmartCharge_Generelle_betingelser_v2021.06.pdf'; //res.TermsAndConditionToAcceptForAStationOwner.en || res.TermsAndConditionToAcceptForAStationOwner.nb;///res.TermsAndConditionToAcceptForAStationOwner.en || res.TermsAndConditionToAcceptForAStationOwner.nb;
                dialogRef.componentInstance.userID = res.user.PK_UserID;
                dialogRef.afterClosed().subscribe(() => {
                  resolve(res);
                });
              } else {
                resolve(res);
              }
            } else if (res === 'wrongUsernameOrPassword') {
              reject(this.translate.instant('WRONG_USERNAME_OR_PASSWORD'));
            } else {
              reject(this.translate.instant('UNAUTHORIZED'));
            }
          },
          error: (err) => {
            if (
              err &&
              err.errorName &&
              err.errorName.toUpperCase() === 'UNAUTHORIZED'
            ) {
              reject({
                reason: this.translate.instant('NO_PORTAL_PERMISSION'),
                extra: this.translate.instant('NO_PERMISSION_EXTRA_HELP')
              });
            } else {
              reject(this.translate.instant('SOMETHING_WENT_WRONG'));
            }
          }
        });
      }
    });
  }

  loginAbraUser(res: any) {
    if (
      res &&
      res.accessToken &&
      res.rToken &&
      res.customer &&
      res.user
    ) {
      if (res.MustConfirmCompanyInformation) {
        this.redirectTo = '/billing';
      }
      this.init(res, this.gf.guid4());

      this.getRoles().subscribe({
        next: (roles) => {
          console.log(roles);
          const permissions = roles?.datas || [];
          if (!permissions.length) {
            this.dialogService.alert(
              this.translate.instant('NO_PORTAL_PERMISSION'),
              null,
              this.translate.instant('NO_PERMISSION_EXTRA_HELP')
            ).subscribe((res) => {
              this.logout();
            });

          } else {
            this.userService.storePermissions(permissions, res.rToken);
            if (res.TermsAndConditionToAcceptForAStationOwner) {
              let dialogRef: MatDialogRef<TermsAndConditionsComponent>;
              dialogRef = this.dialog.open(TermsAndConditionsComponent, {
                disableClose: true,
                autoFocus: false
              });
              dialogRef.componentInstance.termsURL =
                'https://f.hubspotusercontent40.net/hubfs/2283248/Content/SmartCharge_Generelle_betingelser_v2021.06.pdf'; //res.TermsAndConditionToAcceptForAStationOwner.en || res.TermsAndConditionToAcceptForAStationOwner.nb;///res.TermsAndConditionToAcceptForAStationOwner.en || res.TermsAndConditionToAcceptForAStationOwner.nb;
              dialogRef.componentInstance.userID = res.user.PK_UserID;
              dialogRef.afterClosed().subscribe(() => {
              });
            }
            if (this.redirectTo) {
              this.router.navigate([this.redirectTo]);
            } else {
              this.router.navigate(['']);
            }
          }
        }
      })
    }
  }

  init(loginObject, appID) {
    //Setting style
    this.styleService.init(loginObject?.DashboardPreferences);

    this.userService.storeUser(
      loginObject.customer,
      loginObject.accessToken,
      loginObject.rToken,
      appID,
      loginObject.user.FK_UserTypeID,
      loginObject.Roles,
      loginObject.CompaniesUsersHasAccessTo,
      loginObject.OperatorsUserHasAccessTo,
      loginObject.InstallersUserHasAccessTo,
      loginObject.userPreferences
    );
  }

  async logout(redirect?) {
    const seenChangeLogs = localStorage.getItem('HW_seenItems[yZmo1J]');
    const readChangeLogs = localStorage.getItem('HW_readItems[yZmo1J]');
    this.userService.clearUser();
    this.styleService.clearStyle();
    this.tokenService.clearToken();
    this.clearService.clearAll();
    this.redirectTo = null;
    if (this.whitelabelService.isFellTech()) {
      await signOut();
    } else {
      if (redirect) {
        let env = localStorage.getItem('environment');
        localStorage.clear();
        if(seenChangeLogs) localStorage.setItem('HW_seenItems[yZmo1J]', seenChangeLogs);
        if(readChangeLogs) localStorage.setItem('HW_readItems[yZmo1J]', readChangeLogs);
        if (env === 'staging' || env === 'debug' || env === 'sandbox') {
          localStorage.setItem('environment', env);
        }
        this.router.navigate(['auth/login']);
        //location.href = "/#/auth/login"
        //location.replace('/#/auth/login');
      }
    }

  }

  getRoles() {
    return this._http.get(`UserRoles/RolesOfAUser`);
  }
}
