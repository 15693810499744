import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'sc-user-search-dialog',
  templateUrl: './user-search-dialog.component.html',
  styleUrls: ['./user-search-dialog.component.scss']
})
export class UserSearchDialogComponent {
  customer: any;
  constructor(public dialogRef: MatDialogRef<UserSearchDialogComponent>) {}

  selectCustomer(customer) {
    this.customer = customer;
  }
  clearSearch() {
    this.customer = null;
  }
}
