<mat-form-field
  class="super-search"
  [ngClass]="styleClass"
  [floatLabel]="floatLabel">
  <input
    type="text"
    matInput
    #autoComplete
    [required]="required"
    [matAutocomplete]="auto"
    placeholder="{{ 'SUPER_SEARCH_TEXT_124' | translate }}"
    (keyup)="onSearch($event)"
    (focus)="initSocket()"
    (blur)="clearSocket()"
    [(ngModel)]="searchInput"
    name="station" />
  <button
    matSuffix
    mat-icon-button
    type="button"
    class="toolbar-button"
    *ngIf="searchInput"
    (click)="clearSearch()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-error *ngIf="errorText">{{ errorText }}</mat-error>
</mat-form-field>
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayFn"
  (optionSelected)="selectItem($event)">
  <mat-option
    *ngIf="enter && searchedStations.length > 1"
    [value]="searchInput">
    <span class="search-option">
      <span *ngIf="!searchInput">{{ 'SHOW_ALL_LOCATIONS' | translate }}</span>
      <span *ngIf="searchInput">
        {{
          'SHOW_ALL_LOCATIONS_VARIABLE'
            | translate : { searchInput: searchInput }
        }}
      </span>
    </span>
  </mat-option>
  <mat-option
    *ngFor="let item of searchedStations"
    [disabled]="accessComponent && !item.canSee"
    [value]="item">
    <div class="flex">
      <span class="search-option">
        <mat-icon
          *ngIf="item.PointName"
          class="txt-gray"
          matTooltip="{{ 'CHARGE_POINT' | translate }}">
          power
        </mat-icon>
        <span
          *ngIf="item.PointName"
          [innerHTML]="item.PointName | highlight : searchInput"></span>
        <span
          *ngIf="item.PointName"
          class="address"
          [innerHTML]="item.StationName | highlight : searchInput"></span>
        <span
          *ngIf="item.PointName"
          class="address"
          [innerHTML]="item.DeviceID | highlight : searchInput"></span>

        <mat-icon
          *ngIf="!item.PointName"
          class="txt-gray"
          matTooltip="{{ 'CHARGING_LOCATION' | translate }}">
          location_on
        </mat-icon>
        <span
          *ngIf="!item.PointName"
          [innerHTML]="item.Name | highlight : searchInput"></span>
        <span
          *ngIf="!item.PointName"
          class="address"
          [innerHTML]="createAddress(item) | highlight : searchInput"></span>
      </span>
      <!--<span class="operator-logo" *canSee="userType.Admin">
        <div class="flex-center">
          <img
            [matTooltip]="item.OperatorName"
            [src]="item.OperatorLogoURL || 'assets/img/current_c.svg'" />
        </div>
      </span>-->
    </div>
  </mat-option>
</mat-autocomplete>
