import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  public message: string;
  public helpText: string;
  public type: string;

  constructor(public dialogRef: MatDialogRef<AlertComponent>) {}
}
