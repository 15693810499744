import { tap, map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../../services';

import { Operator, OperatorUser } from './operator.model';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { mapData, mapSuccess } from '../rxjs';
import { OperatorPackage } from '..';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { RoamingAuthorization } from '../roaming/roaming-authorization.model';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {
  constructor(
    private _http: HttpInterceptorService,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private translate: TranslateService
  ) { }

  getOperatorsCanSee() {
    return this._http.get('Operators/OperatorsICanSee').pipe(
      mapData(),
      tap({
        error: (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
        }
      })
    );
  }
  // @TODO: Feels like an antiparten to write it this way;
  getSingle(operatorID: number) {
    return this._http.get(`Operators/${operatorID}`).pipe(
      mapData(),
      tap({
        error: (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
        }
      })
    );
  }
  getUsers(operatorID: number) {
    return this._http.get(`Operators/GetAllUsers/${operatorID}`).pipe(
      mergeMap(mapData()),
      mergeMap(mapSuccess()),
      tap({
        next: (data: any) => {
          if (data.success) {
            return data.datas;
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            return [];
          }
        },
        error: (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          return [];
        }
      })
    );
  }
  add(operator: Operator, operatorPackage: OperatorPackage) {
    operator.isActive = true;

    return this._http
      .post('Operators', { Operator: operator, Package: operatorPackage })
      .pipe(
        mapData(),

        tap({
          error: (err) => {
            this.snackBar.open(
              `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              })}`,
              this.translate.instant('CLOSE')
            );
          }
        })
      );
  }

  addUser(operatorUser: OperatorUser) {
    return this._http.post('Operators/AddUserAccess', operatorUser).pipe(
      mapSuccess(),
      tap({
        next: (success) => {
          if (!success) {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            return false;
          }

          return success;
        },
        error: (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
        }
      })
    );
  }
  updateUser(operatorUser: OperatorUser) {
    return this._http.put('Operators/ChangeUserAccess', operatorUser).pipe(
      mapSuccess(),
      tap({
        error: (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
        }
      })
    );
  }
  deleteUser(operatorUser: OperatorUser) {
    // return new Promise((resolve) => {
    //   this.dialog
    //     .prompt(
    //       'Are you sure you want to remove the user from the operator?',
    //       null,
    //       'Remove'
    //     )
    //     .subscribe((doDelete) => {
    //       if (doDelete) {
    //         this._http
    //           .delete(
    //             'Operators/RemoveUserAccess/' +
    //               operatorUser.FK_UserID +
    //               '/' +
    //               operatorUser.FK_OperatorID
    //           )
    //           .subscribe(
    //             (res) => {
    //               if (res.success) {
    //                 resolve(true);
    //               } else {
    //                 this.snackBar.open(
    //                   'Error! Could not remove user.',
    //                   'Close'
    //                 );
    //                 resolve(false);
    //               }
    //             },
    //             (err) => {
    //               this.snackBar.open(
    //                 'Error! Could not remove user. Error: ' + err.errorName,
    //                 'Close'
    //               );
    //               resolve(false);
    //             }
    //           );
    //       } else {
    //         resolve(false);
    //       }
    //     });
    // });
    return this.dialog
      .prompt(
        this.translate.instant('CONFIRM_REMOVE_USER_OPERATOR'),
        null,
        this.translate.instant('REMOVE')
      )
      .pipe(
        tap({
          next: (doDelete) => {
            if (doDelete) {
              return this._http.delete(
                'Operators/RemoveUserAccess/' +
                operatorUser.FK_UserID +
                '/' +
                operatorUser.FK_OperatorID
              );
            }
            return false;
          }
        }),
        tap({
          next: (res) => {
            if (res.success) {
              return true;
            } else {
              this.snackBar.open(
                this.translate.instant('SOMETHING_WENT_WRONG'),
                this.translate.instant('CLOSE')
              );
              return false;
            }
          },
          error: (err) => {
            this.snackBar.open(
              `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              })}`,
              this.translate.instant('CLOSE')
            );
            return false;
          }
        })
      );
  }

  saveOperator(operator: Operator) {
    return this._http.put('Operators', operator).pipe(
      mapSuccess(),
      tap({
        next: (success) => {
          if (!success) {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            return false;
          }

          return success;
        },
        error: (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
        }
      })
    );
  }
}
