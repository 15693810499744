<div class="dialog-container">
  <h2 mat-dialog-title>
    {{ several ? ('ADD_OWNERS' | translate) : ('ADD_OWNER' | translate) }}
  </h2>
  <div mat-dialog-content class="dialog-content">
    <div class="margin-bottom" *ngIf="!several">
      {{ 'ENTER_EMAIL_TO_ADD_OWNER' | translate }}
      <br />
      {{ 'USER_TO_CONFIRM_OWNERSHIP' | translate }}
    </div>
    <form>
      <div *ngIf="!several">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'EMAIL' | translate }}"
            required
            name="email"
            [formControl]="emailCtrl" />
          <mat-error *ngIf="!emailCtrl.value">
            {{ 'EMAIL_IS_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="emailCtrl.value && emailCtrl.hasError('email')">
            {{ 'ENTER_VALID_EMAIL' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="margin-bottom">
        <mat-checkbox [formControl]="authenticationCtrl">
          {{ 'AUTHENTICATION' | translate }}
        </mat-checkbox>
      </div>
      <div class="margin-bottom">
        <mat-checkbox [formControl]="subscriptionCtrl">
          {{ 'SUBSCIPTION_PAID_BY_END_USER' | translate }}
        </mat-checkbox>
      </div>
      <div class="margin-bottom" *ngIf="userType.Operator">
        <mat-checkbox [formControl]="semiRegisterCtrl">
          {{ 'SEMI_REGISTER_USER' | translate }}
        </mat-checkbox>
      </div>
    </form>
  </div>
  <div mat-dialog-actions>
    <button
      mat-raised-button
      [disabled]="loading"
      color="primary"
      (click)="addOwner()">
      <span *ngIf="!loading">{{ 'ADD_OWNER' | translate }}</span>
      <mat-spinner class="button-spinner" *ngIf="loading"></mat-spinner>
    </button>
    <button mat-raised-button (click)="dialogRef.close()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
