<h2 mat-dialog-title>{{ message }}</h2>
<div mat-dialog-content class="dialog-content">
  <p *ngIf="content">{{ content }}</p>
  <form (submit)="submitForm()">
    <mat-form-field *ngIf="textarea">
      <textarea
        matInput
        rows="8"
        placeholder="{{ placeholder }}"
        [required]="!optional"
        name="name"
        [formControl]="inputCtrl"></textarea>
      <mat-hint *ngIf="hint">
        {{ hint }}
      </mat-hint>
      <mat-error *ngIf="inputCtrl.hasError('required')">
        {{ 'REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!textarea" class="w-full">
      <input
        matInput
        placeholder="{{ placeholder }}"
        [required]="!optional"
        name="name"
        [formControl]="inputCtrl" />
      <mat-hint *ngIf="hint">
        {{ hint }}
      </mat-hint>
      <mat-error *ngIf="inputCtrl.hasError('required')">
        {{ 'REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="submitForm()">
    {{ action || ('OK' | translate) }}
  </button>
  <button mat-raised-button (click)="dialogRef.close()">
    {{ 'CANCEL' | translate }}
  </button>
</div>
