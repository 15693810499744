import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent {
  public title: string;
  public okButton: string = 'Ok';
  public cancelButton: string = 'Cancel';
  public content: string;
  public secondOption: string;
  public data: { [key: string]: number | string };
  counter = -1;
  interval: NodeJS.Timeout;

  constructor(public dialogRef: MatDialogRef<PromptComponent>) {}

  handleOkClick = () => {
    if (this.data?.OkCountDownSecond) {
      if (this.interval) {
        clearInterval(this.interval);
        this.dialogRef.close(true);
      } else {
        const second = Number(this.data?.OkCountDownSecond);
        this.counter = second;
        this.interval = setInterval(() => {
          if (this.counter < 1) {
            clearInterval(this.interval);
            this.dialogRef.close(true);
          } else this.counter = this.counter - 1;
        }, 1000);
      }
    } else {
      this.dialogRef.close(true);
    }
  };

  firstToUpper(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
