import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { Observable, map } from 'rxjs';
import { Whitelabel } from 'src/app/classes/Whitelabel';
import { AppConfig } from '@smartcharge/shared/core/config';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {
  whitelabel: WhitelabelInterface;
  whitelabelID: string = null;
  isWhiteLabel: boolean = false;

  constructor(private _http: HttpInterceptorService) {
    this.whitelabel = this.getWhitelabelByDomain();
    this.whitelabelID = this.whitelabel.whitelabelID;

    // Utility property
    this.isWhiteLabel = this.whitelabelID !== null;
  }

  init(): Promise<void> {
    //This function will run before everything else in the app
    return new Promise((resolve) => {
      this.loadWhitelabel();
      resolve();
    });
  }

  getConfig(): AppConfig {
    return {
      appToken: this.whitelabelID,
      availableLangs: this.whitelabel.availableLangs,
      defaultLang: this.whitelabel.defaultLang,
      isWhitelabel: this.isWhiteLabel,
      showTitle: false,
      title: this.whitelabel.title
    };
  }

  getWhitelabel(tokenId: string): Observable<Whitelabel> {
    return this._http
      .get(`Whitelabels/${tokenId}`)
      .pipe(map((res) => res.datas));
  }

  getNameByToken(tokenId: string | null): string | undefined {
    const whitelabels = new WhitelabelClass();
    const operator = tokenId
      ? Object.entries(AppTokens).find(
          ([_name, token]) => token === tokenId
        )?.[0]
      : 'smartcharge';

    return whitelabels[operator]?.title;
  }

  isFellTech(): boolean {
    return this.whitelabel.whitelabelID === AppTokens.abracharge
  }

  saveWhitelabel(whitelabel: Whitelabel): Observable<Whitelabel> {
    return this._http.put('Whitelabels', whitelabel);
  }

  private getWhitelabelByDomain(): WhitelabelInterface {
    let domain: any = window.location.hostname;
    //Removes all before and including " :// "
    if (domain.includes('://')) {
      domain = /:\/\/([^\/]+)/.exec(domain)[1];
    }

    let domains: any[] = domain.split('.');
    let subdomain = '';

    if (['www', 'app', 'portal'].includes(domains[0].toLowerCase())) {
      //Removes www, app and portal
      domains.shift();
    }

    subdomain = domains[0].toLowerCase();
    const operators = new WhitelabelClass();

    if (subdomain === 'felltech') {
      subdomain = 'abracharge';
    }

    return subdomain in operators
      ? operators[subdomain]
      : operators.smartcharge;
  }

  private loadWhitelabel(): void {
    if (this.whitelabelID) {
      this.getWhitelabel(this.whitelabelID).subscribe({
        next: (res) => {
          this.whitelabel.whiteLabelDetails = {
            AppName: res.AppName,
            FK_OperatorID: res.FK_OperatorID
          };
        },
        error: (err) => {}
      });
    }
  }
}
enum AppTokens {
  smartcharge = '00000000-0000-0000-0000-000000000000',
  fjordkraft = '064034d5-40ae-4f7b-a54a-3ee1650a27af',
  elywhere = 'ad783bd2-58b4-41e5-9767-17187009c3f2',
  electricfreeway = '4f139d0e-b95c-441b-8519-304113c6856d',
  chargebnb = '9a7f87d7-d0fd-464b-badb-9dfea9dba4f1',
  glup = '8bd9c35b-6ff9-452f-aee4-e00290c8e4fd',
  rexel = '946feea6-1c53-45ff-81cf-ed770f2d3307',
  wennstrom = 'abff5760-26f5-43cd-994c-e2519901da0f',
  abracharge = '62d8aee3-7e03-472c-8067-6e78a4ed1497'
}

export class WhitelabelClass {
  smartcharge: WhitelabelInterface = {
    title: 'CURRENT',
    whitelabelID: null,
    defaultCurrency: 'NOK',
    availableLangs: ['en', 'nb', 'de', 'da', 'sv'],
    defaultLang: 'en',
    defaultStyle: {
      background: '#424242',
      color: '#fff',
      img: 'assets/img/current_logo.svg',
      text: 'CURRENT',
      height: 70
    },
    images: {
      favIcon: {
        img: 'assets/img/current_c.svg',
        width: 32
      },
      login: {
        img: 'assets/img/current_logo.svg',
        width: 150
      },
      default: {
        img: 'assets/img/current_logo.svg',
        width: 100
      },
      menu: {
        img: 'assets/img/current_logo.svg',
        width: 100
      },
      loading: {
        img: 'assets/img/current_logo.svg',
        width: 100
      }
    }
  };
  abracharge: WhitelabelInterface = {
    title: 'Abra Charge',
    whitelabelID: AppTokens.abracharge,
    defaultCurrency: 'NOK',
    availableLangs: ['en', 'nb', 'de', 'da', 'sv'],
    defaultLang: 'en',
    defaultStyle: {
      background: '#424242',
      color: '#fff',
      img: 'assets/img/abracharge/abra_logo_medium.png',
      text: null,
      height: 70
    },
    images: {
      favIcon: {
        img: 'assets/img/abracharge/abra_favicon.png',
        width: 32
      },
      login: {
        img: 'assets/img/abracharge/abra_logo_medium.png',
        width: 150
      },
      default: {
        img: 'assets/img/abracharge/abra_logo_medium.png',
        width: 100
      },
      menu: {
        img: 'assets/img/abracharge/abra_logo_medium.png',
        width: 100
      },
      loading: {
        img: 'assets/img/abracharge/abra_logo_medium.png',
        width: 100
      }
    }
  };
  fjordkraft: WhitelabelInterface = {
    title: 'Fjordkraft',
    whitelabelID: AppTokens.fjordkraft,
    defaultCurrency: 'NOK',
    availableLangs: ['en', 'nb'],
    defaultLang: 'nb',
    defaultStyle: {
      background: '#f50',
      color: '#fff',
      img: 'assets/img/fjordkraft/Fjordkraft_logo.svg',
      text: null,
      height: 40
    },
    images: {
      favIcon: {
        img: 'assets/img/fjordkraft/favicon.ico',
        width: 32
      },
      login: {
        img: 'assets/img/fjordkraft/Fjordkraft_logo.svg',
        width: 200
      },
      default: {
        img: 'assets/img/fjordkraft/logo_notext.png',
        width: 100
      },
      menu: {
        img: 'assets/img/fjordkraft/logo.png',
        width: 50
      },
      loading: {
        img: 'assets/img/fjordkraft/Fjordkraft_logo.svg',
        width: 100
      }
    }
  };
  elywhere: WhitelabelInterface = {
    title: 'Elywhere',
    whitelabelID: AppTokens.elywhere,
    defaultCurrency: 'NOK',
    availableLangs: ['en', 'nb', 'da', 'sv'],
    defaultLang: 'en',
    defaultStyle: {
      background: '#424242',
      color: '#fff',
      img: 'assets/img/elywhere/logo_notext.png',
      text: 'Elywhere',
      height: 70
    },
    images: {
      favIcon: {
        img: 'assets/img/elywhere/favicon.ico',
        width: 32
      },
      login: {
        img: 'assets/img/elywhere/logo.png',
        width: 150
      },
      default: {
        img: 'assets/img/elywhere/logo_notext.png',
        width: 100
      },
      menu: {
        img: 'assets/img/elywhere/logo.png',
        width: 100
      },
      loading: {
        img: 'assets/img/elywhere/logo.png',
        width: 100
      }
    }
  };
  electricfreeway: WhitelabelInterface = {
    title: 'Electric Freeway',
    whitelabelID: AppTokens.electricfreeway,
    defaultCurrency: 'NOK',
    availableLangs: ['en', 'nb', 'da', 'sv'],
    defaultLang: 'en',
    defaultStyle: {
      background: '#FFFFFF',
      color: '#000000',
      img: 'assets/img/electricfreeway/logo_notext.png',
      text: 'Electric Freeway',
      height: 70
    },
    images: {
      favIcon: {
        img: 'assets/img/electricfreeway/favicon.ico',
        width: 32
      },
      login: {
        img: 'assets/img/electricfreeway/logo.png',
        width: 250
      },
      default: {
        img: 'assets/img/electricfreeway/logo_notext.png',
        width: 100
      },
      menu: {
        img: 'assets/img/electricfreeway/logo_notext.png',
        width: 100
      },
      loading: {
        img: 'assets/img/electricfreeway/logo.png',
        width: 100
      }
    }
  };
  chargebnb: WhitelabelInterface = {
    title: 'ChargeBnB',
    whitelabelID: AppTokens.chargebnb,
    defaultCurrency: 'NOK',
    availableLangs: ['en', 'nb', 'da', 'sv'],
    defaultLang: 'en',
    defaultStyle: {
      background: '#5ac388',
      color: '#0e284b',
      img: 'assets/img/chargebnb/logo_notext.png',
      text: 'ChargeBnB',
      height: 50
    },
    images: {
      favIcon: {
        img: 'assets/img/chargebnb/favicon.ico',
        width: 32
      },
      login: {
        img: 'assets/img/chargebnb/logo_notext.png',
        width: 150
      },
      default: {
        img: 'assets/img/chargebnb/logo_notext.png',
        width: 100
      },
      menu: {
        img: 'assets/img/chargebnb/logo_notext.png',
        width: 100
      },
      loading: {
        img: 'assets/img/chargebnb/logo_notext.png',
        width: 100
      }
    }
  };
  glup: WhitelabelInterface = {
    title: 'Glup',
    whitelabelID: AppTokens.glup,
    defaultCurrency: 'NOK',
    availableLangs: ['en', 'nb', 'da', 'sv'],
    defaultLang: 'nb',
    defaultStyle: {
      background: '#00bcf2',
      color: '#001f38',
      img: 'assets/img/glup/normal_logo.png',
      text: 'Glup',
      height: 30
    },
    images: {
      favIcon: {
        img: 'assets/img/glup/favicon.ico',
        width: 32
      },
      login: {
        img: 'assets/img/glup/normal_logo.png',
        width: 150
      },
      default: {
        img: 'assets/img/glup/logo.png',
        width: 100
      },
      menu: {
        img: 'assets/img/glup/normal_logo.png',
        width: 100
      },
      loading: {
        img: 'assets/img/glup/normal_logo.png',
        width: 100
      }
    }
  };
  rexel: WhitelabelInterface = {
    title: 'Rexel Charge',
    whitelabelID: AppTokens.rexel,
    defaultCurrency: 'SEK',
    availableLangs: ['en', 'nb', 'da', 'sv'],
    defaultLang: 'sv',
    defaultStyle: {
      background: '#F8F8F8',
      color: '#0F1923',
      img: 'assets/img/rexel/rexel_logo.png',
      text: 'Rexel Charge',
      height: 50
    },
    images: {
      favIcon: {
        img: 'assets/img/rexel/favicon.ico',
        width: 32
      },
      login: {
        img: 'assets/img/rexel/rexel_logo.png',
        width: 150
      },
      default: {
        img: 'assets/img/rexel/rexel_logo.png',
        width: 100
      },
      menu: {
        img: 'assets/img/rexel/rexel_logo.png',
        width: 100
      },
      loading: {
        img: 'assets/img/rexel/rexel_logo.png',
        width: 100
      }
    }
  };
  wennstrom: WhitelabelInterface = {
    title: 'Wennstrøm',
    defaultCurrency: 'NOK',
    whitelabelID: AppTokens.wennstrom,
    availableLangs: ['en', 'nb', 'da', 'sv'],
    defaultLang: 'nb',
    defaultStyle: {
      background: '#EBEBEB',
      color: '#222221',
      img: 'assets/img/wennstrom/wennstrom_logo.png',
      text: null,
      height: 35
    },
    images: {
      favIcon: {
        img: 'assets/img/wennstrom/favicon.ico',
        width: 32
      },
      login: {
        img: 'assets/img/wennstrom/wennstrom_logo.png',
        width: 150
      },
      default: {
        img: 'assets/img/wennstrom/wennstrom_logo.png',
        width: 100
      },
      menu: {
        img: 'assets/img/wennstrom/wennstrom_logo.png',
        width: 100
      },
      loading: {
        img: 'assets/img/wennstrom/wennstrom_logo.png',
        width: 100
      }
    }
  };
}

export class WhitelabelInterface {
  availableLangs: string[];
  defaultLang: string;
  images: WhitelabelImages = new WhitelabelImages();
  whiteLabelDetails? = new WhiteLabelDetails();
  title: string;
  whitelabelID: string;
  defaultStyle: any;
  defaultCurrency: string;
}

class WhitelabelImages {
  login: WhitelabelImage = new WhitelabelImage();
  default: WhitelabelImage = new WhitelabelImage();
  menu: WhitelabelImage = new WhitelabelImage();
  loading: WhitelabelImage = new WhitelabelImage();
  favIcon = new WhitelabelImage();
}

class WhitelabelImage {
  img: string;
  width: number;
}

class WhiteLabelDetails {
  AppName: string;
  FK_OperatorID: number;
}
