import { Injectable } from '@angular/core';
import { Defaults } from '../../defaults';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { WhitelabelService } from '../whitelabel/whitelabel.service';

@Injectable()
export class StyleService {
  style: DefaultStyle = this.whitelabelService.whitelabel.defaultStyle; //new DefaultStyle(); //Inits style with default values
  styleSettings: any;

  constructor(
    private defaults: Defaults,
    private _http: HttpInterceptorService,
    private whitelabelService: WhitelabelService
  ) {
    this.init();
  }
  clear() {
    this.style = this.whitelabelService.whitelabel.defaultStyle; //new DefaultStyle(); //Inits style with default values
    this.styleSettings = null;
  }
  init(style?) {
    //Runs when app is launced and when user logs in

    if (style) {
      //Runs when user logs in
      this.styleSettings = style;

      style.DesignSettings = JSON.parse(style.DesignSettings);
      this.style = this.mergeStyles(this.style, style.DesignSettings);
      if (style.logoURL) {
        this.style.img = style.logoURL;
        style.DesignSettings.img = this.style.img;
      }
      localStorage.setItem('style', JSON.stringify(style));
    } else {
      this.get().then((style) => {
        this.style = style;
      });
    }
  }
  get(): Promise<any> {
    return new Promise((resolve) => {
      let style: any = localStorage.getItem('style');

      if (style) {
        style = JSON.parse(style);

        this.styleSettings = style;
        this.style = this.mergeStyles(this.style, style.DesignSettings);

        if (this.style.img && this.style.img.includes('api.smartcharge')) {
          this.style.img =
            'https://apistoragesc.blob.core.windows.net/dashboardimagessc/' +
            this.style.img.split('/').pop();

          this.set(this.style);
        }

        resolve(this.style);
      } else {
        let style = this.whitelabelService.whitelabel.defaultStyle; //new DefaultStyle();

        resolve(style); //Default style
      }
    });
  }
  set(style): Promise<any> {
    return new Promise((resolve) => {
      let oldStyle: any = localStorage.getItem('style');
      if (oldStyle) {
        oldStyle = JSON.parse(oldStyle);

        style = this.mergeStyles(
          oldStyle.DesignSettings ||
            this.whitelabelService.whitelabel.defaultStyle,
          style
        );
        oldStyle.DesignSettings = style;
        localStorage.setItem('style', JSON.stringify(oldStyle));
        resolve(oldStyle.DesignSettings);
      } else {
        this.styleSettings.DesignSettings = style;
        localStorage.setItem('style', JSON.stringify(this.styleSettings));
        resolve(style);
      }
    });
  }
  clearStyle() {
    this.style = this.whitelabelService.whitelabel.defaultStyle; //new DefaultStyle();
    this.styleSettings = new DesignSettings();
    localStorage.removeItem('style');
  }
  uploadImage(file) {
    return new Promise((resolve, reject) => {
      if (!file) reject('No file');
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);

      this._http.post('DashboardPreferences/UploadImage', formData).subscribe(
        (res) => {
          if (res.datas) {
            this.style.img = res.datas.logoURL;
            this.set(this.style).then((res) => {
              resolve(res.img);
            });
          } else {
            reject(res);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  private mergeStyles(object, newObject) {
    //Merging two objects. NewObject overwrites if it is not empty
    Loop: for (let key in object) {
      for (let nkey in newObject) {
        if (key === nkey) {
          if (newObject[nkey] || nkey === 'text') {
            object[key] = newObject[nkey];
          }
          continue Loop;
        }
      }
    }
    return object;
  }
}
export class DesignSettings {
  PK_DashboardPreferencesID = 0;
  FK_CustomerID = 0;
  DesignSettings = {};
  logoURL;
}
export class DefaultStyle {
  background = '#424242';
  color = '#fff';
  img = 'assets/img/current_logo.svg';
  text = 'CURRENT';
  height = 40;
}
