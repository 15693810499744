import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomerInformationParameters, HashAlgorithmTypes, IdTokenTypes, OcppVersionEnum } from 'src/app/lib/commands';
import { ChargingPoint } from 'src/app/lib/stations/stations.model';

@Component({
  selector: 'sc-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss']
})
export class CustomerInformationComponent implements OnInit {

  title: string = '';
  action: string = '';
  idTokenTypes = IdTokenTypes;
  hasAlgorithmTypes = HashAlgorithmTypes;

  customerInformationForm: FormGroup;
  additionalInfo: FormArray;

  constructor(public dialogRef: MatDialogRef<CustomerInformationComponent>) { }

  ngOnInit() {
    this.customerInformationForm = new FormGroup({
      report: new FormControl(false, [Validators.required]),
      clear: new FormControl(false, [Validators.required]),
      customerIdentifier: new FormControl('', []),
      idToken: new FormControl('', []),
      customerCertificate: new FormControl('', []),
      type: new FormControl(null, []),
      additionalInfo: new FormArray([]),
      hashAlgorithm: new FormControl(null, []),
      issuerNameHash: new FormControl('', []),
      issuerKeyHash: new FormControl('', []),
      serialNumber: new FormControl('', []),
    });

    this.customerInformationForm.valueChanges.subscribe({
      next: (changes) => {
        if (changes.idToken) {
          this.customerInformationForm.controls.type.setValidators([Validators.required]);
        } else {
          this.customerInformationForm.controls.type.setValidators([]);
        }

        if (changes.hashAlgorithm) {
          this.customerInformationForm.controls.issuerNameHash.setValidators([Validators.required]);
          this.customerInformationForm.controls.issuerKeyHash.setValidators([Validators.required]);
          this.customerInformationForm.controls.serialNumber.setValidators([Validators.required]);
        } else {
          this.customerInformationForm.controls.issuerNameHash.setValidators([]);
          this.customerInformationForm.controls.issuerKeyHash.setValidators([]);
          this.customerInformationForm.controls.serialNumber.setValidators([]);
        }

        this.customerInformationForm.controls.type.updateValueAndValidity({ onlySelf: true });
        this.customerInformationForm.controls.issuerNameHash.updateValueAndValidity({ onlySelf: true });
        this.customerInformationForm.controls.issuerKeyHash.updateValueAndValidity({ onlySelf: true });
        this.customerInformationForm.controls.serialNumber.updateValueAndValidity({ onlySelf: true });

      }
    })

    this.additionalInfo = this.customerInformationForm.get('additionalInfo') as FormArray;
  }

  createAdditionalInfoForm() {
    return new FormGroup({
      additionalIdToken: new FormControl(''),
      type: new FormControl('')
    })
  }

  addAdditionalInfo() {
    this.additionalInfo.push(this.createAdditionalInfoForm());
  }

  deleteAdditionalInfo(index: number) {
    this.additionalInfo.removeAt(index);
  }

  handleFormSubmit() {
    if (this.customerInformationForm.valid) {
      const {
        clear, report, customerIdentifier,
        idToken, additionalInfo, type, hashAlgorithm,
        issuerNameHash, issuerKeyHash, serialNumber

      } = this.customerInformationForm.value;

      const customerInfoParams: CustomerInformationParameters = {
        clear: clear,
        report: report,
        customerIdentifier: customerIdentifier,
        idToken: null,
        customerCertificate: null,
      }
      if (idToken) {
        customerInfoParams.idToken = {
          idToken: idToken,
          additionalInfo: additionalInfo,
          type: type
        }
      }
      if (hashAlgorithm) {
        customerInfoParams.customerCertificate = {
          hashAlgorithm: hashAlgorithm,
          issuerNameHash: issuerNameHash,
          issuerKeyHash: issuerKeyHash,
          serialNumber: serialNumber
        }
      }
      this.dialogRef.close(customerInfoParams);
    }

  }

}
