import { UserType } from 'src/app/user-roles';
import { MenuItem } from '../main-sidenav/menu-items';

export const adminMenuItems: MenuItem[] = [
  {
    title: '',
    pages: [
      {
        title: 'BACK_TO_DASHBOARD',
        url: '/',
        icon: 'arrow_back',
        exactMatch: true
      }
    ]
  },
  {
    title: 'MANAGE',
    pages: [
      {
        title: 'PAYOUTS',
        url: '/admin/payouts',
        icon: 'attach_money'
      },
      {
        title: 'USERS',
        url: '/admin/users',
        icon: 'person_search'
      },
      {
        title: 'OPERATORS',
        url: '/admin/operators',
        icon: 'headset_mic'
      },
      {
        title: 'INSTALLERS',
        url: '/admin/installers',
        icon: 'engineering'
      },
      {
        title: 'OPERATOR_PACKAGES',
        url: '/admin/operator-packages',
        icon: 'shopping_cart'
      },
      {
        title: 'COMPANIES',
        url: '/admin/companies',
        icon: 'business'
      },
      {
        title: 'MANUFACTURERS',
        url: '/admin/manufacturers',
        icon: 'factory'
      },
      {
        title: 'ROAMING_PLATFORMS',
        url: '/admin/roaming-platforms',
        icon: 'language'
      },
      {
        title: 'BOX_MODELS',
        url: '/admin/box-models',
        icon: 'ev_station'
      },
      {
        title: 'Vendor errors',
        url: '/admin/vendor-erros',
        icon: 'error'
      },
      {
        title: 'SUPPORT_LINKS',
        url: '/admin/helpdesk',
        icon: 'link'
      },
      {
        title: 'PAYMENT_TERMINALS',
        url: '/admin/terminals',
        icon: 'point_of_sale'
      }
    ]
  },
  {
    title: 'CONTROL',
    roles: UserType.SuperUser,
    pages: [
      {
        title: 'API_CALLER',
        url: '/admin/api-caller',
        icon: 'api'
      },
      {
        title: 'COMMAND_RESPONSES',
        url: '/admin/command-responses',
        roles: UserType.SuperUser,
        icon: 'checklist'
      },
      {
        title: 'WEB_BACKEND',
        url: '/admin/web-backend/websocket-connections',
        roles: UserType.SuperUser,
        icon: 'cloud'
      }
    ]
  },
  {
    title: 'REPORTS',
    pages: [
      {
        title: 'SYSTEM_USAGE',
        url: '/admin/system-usage',
        icon: 'list'
      },
      {
        title: 'LOCATION_USAGE',
        url: '/admin/station-usage',
        icon: 'receipt_long'
      }
    ]
  },
  {
    title: 'LOGS',
    roles: UserType.SuperUser,
    pages: [
      {
        title: 'CHANGELOG',
        url: '/admin/changelog',
        icon: 'list_alt'
      },
      {
        title: 'ERROR_LOGS',
        url: '/admin/error-log',
        icon: 'assignment_late'
      },
      {
        title: 'SMS_ACTIVITY',
        url: '/admin/sms-logs',
        icon: 'send_to_mobile'
      },
      {
        title: 'PUSH_ACTIVITY',
        url: '/admin/push-notification-logs',
        icon: 'ad_units'
      },
      {
        title: 'LOGIN_ATTEMPTS',
        url: '/admin/login-attempts',
        icon: 'password'
      }
    ]
  }
];
