//Permissions are for modifying the view. Access is controlled backend

import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { snackbarConfig } from '../../defaults';
import { TokenService } from '../auth/token/token.service';
import { RoleType } from 'src/app/classes/role';
import { AES } from 'jscrypto/es6/AES';
import { Utf8 } from 'jscrypto/es6';

import { DialogService } from '../dialog/dialog.service';
import { AuthService } from '../auth/auth.service';
import { UserType } from 'src/app/user-roles';
import { OperatorRoles } from 'src/app/classes/OperatorAccess';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PermissionsService {
  constructor(
    private _http: HttpInterceptorService,
    private snackBar: MatSnackBar,
    private tokenService: TokenService,
    private dialogService: DialogService,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  getRole(roleID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('Permissions/' + roleID).subscribe(
        (role) => {
          if (role && role.datas) {
            resolve(role.datas);
          }
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve({});
        }
      );
    });
  }
  getRoles(companyID): Promise<any> {
    return new Promise((resolve) => {
      this._http
        .get('Permissions/PermissionsOwnedByACompany/' + companyID + '/true')
        .subscribe(
          (roles) => {
            if (roles && roles.datas) {
              resolve(roles.datas);
            } else {
              this.snackBar.open(
                this.translate.instant('SOMETHING_WENT_WRONG'),
                this.translate.instant('CLOSE')
              );
              resolve([]);
            }
          },
          (err) => {
            this.snackBar.open(
              `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              })}`,
              this.translate.instant('CLOSE')
            );
            resolve([]);
          }
        );
    });
  }
  createRole(role): Promise<any> {
    return new Promise((resolve) => {
      if (!role.FK_CompanyID) role.FK_CompanyID = 0; //Default role

      this._http.post('Permissions', role).subscribe(
        (res) => {
          if (res && res.datas) {
            resolve(res.datas);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  updateRole(role): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('Permissions', role).subscribe(
        (res) => {
          if (res && res.success) {
            resolve(true);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  updatePermission(permission): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('UserRoles', permission).subscribe(
        (res) => {
          if (res && res.success) {
            resolve(true);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  createPermission(permission): Promise<any> {
    return new Promise((resolve) => {
      this._http.post('UserRoles', permission).subscribe(
        (res) => {
          if (res && res.datas && res.datas.PK_UserRole_ID) {
            resolve(res.datas);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  deletePermission(permission): Promise<any> {
    return new Promise((resolve) => {
      this._http.delete('UserRoles/' + permission.PK_UserRole_ID).subscribe(
        (res) => {
          if (res && res.success) {
            this.snackBar.open(
              this.translate.instant('PERMISSION_DELETED'),
              null,
              snackbarConfig
            );
            resolve(true);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  deleteRole(role): Promise<any> {
    return new Promise((resolve) => {
      //Ask
      //Deleting this role will also remove the linked permissions
      this.dialogService
        .prompt(
          this.translate.instant('CONFIRM_DELETE_ROLE'),
          this.translate.instant('DELETE_ROLE_WARNING'),
          this.translate.instant('DELETE')
        )
        .subscribe((doDelete) => {
          if (doDelete) {
            this._http
              .delete('Permissions/' + role.PK_PermissionRole_ID)
              .subscribe(
                (res) => {
                  if (res && res.success) {
                    this.snackBar.open(
                      this.translate.instant('ROLE_DELETED'),
                      null,
                      snackbarConfig
                    );
                    resolve(true);
                  } else {
                    this.snackBar.open(
                      this.translate.instant('SOMETHING_WENT_WRONG'),
                      this.translate.instant('CLOSE')
                    );
                    resolve(false);
                  }
                },
                (err) => {
                  this.snackBar.open(
                    `${this.translate.instant(
                      'SOMETHING_WENT_WRONG_WITH_ERROR',
                      { errorName: err.errorName }
                    )}`,
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  getGroupPermissions(groupID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('GroupsPermissions/ByGroup/' + groupID).subscribe(
        (groupPermissions) => {
          if (groupPermissions) {
            resolve(groupPermissions);
          } else {
            resolve([]);
          }
        },
        (err) => {
          resolve([]);
        }
      );
    });
  }
  checkAccess(component: string, stationID): Access {
    let access: Access = new Access(); //Default access = no access

    //Check admin
    let token = this.tokenService.getToken();
    if (token && typeof parseInt(token.UserType) == 'number') {
      if ((parseInt(token.UserType) & UserType.Admin) == UserType.Admin) {
        return new Access('StationOwner');
      }
    }

    //Get permissions
    let permissionsByStations: any = this.getPermission();

    //Filter by stationID
    let permissionsByStation = permissionsByStations.filter(
      (permByStation) =>
        permByStation.ChargingStationID === parseInt(stationID) &&
        permByStation.AccessLevel !== 'ClaimedUser'
    )[0];

    //Check if user has any permissions for the location
    if (!permissionsByStation) {
      return access;
    }

    //Check operator access
    if (permissionsByStation.OperatesStation) {
      //Check if the component exists in the operator roles
      if (OperatorRoles[component]) {
        //Check if the operator has access to the component
        if (
          (parseInt(permissionsByStation.OperatorAccess) &
            OperatorRoles[component]) ==
          OperatorRoles[component]
        ) {
          access = new Access('StationOwner');
          access.operator = true;
          return access;
        } //Else no access.
      } else {
        //If the component doesn't exist we allow access. It means the component isn't protected
        access = new Access('StationOwner');
        access.operator = true;
        return access;
      }
    }

    //Check if location owner
    if (permissionsByStation.OwnsTheStation) {
      //maybe use this? permissionsByStation.AccessLevel==='Owner'
      //Full access

      return new Access('StationOwner');
    }

    //Check if installer
    if (permissionsByStation.IsInstallerOnStation) {
      //Skip checking if these access levels. In this case we will checkk the PermissionAssociated
      if (!['UserReports', 'BusinessReports'].includes(component)) {
        //Custom frontend-check. Should have a better system that is only controlled backend
        switch (component) {
          case 'Analytics':
            access = new Access('Allowed');
            break;
          case 'BoxMessages':
            access = new Access('Allowed');
            break;
          case 'BoxSettings':
            access = new Access('CanModify');
            break;
          case 'CommandsOnBoxes':
            access = new Access('Allowed');
            break;
          case 'OrderHistories':
            access = new Access('Allowed');
            break;
          case 'Rules':
            access = new Access('CanModify');
            break;
          case 'StationInformations':
            access = new Access('CanModify');
            break;
          case 'StationLimitations':
            access = new Access('CanDelete');
            break;
        }
        access.installer = true;
        return access;
      }
    }

    //Check permission
    if (permissionsByStation && permissionsByStation.PermissionAssociated) {
      access = new Access(permissionsByStation.PermissionAssociated[component]);
    }

    if (permissionsByStation.IsInstallerOnStation) {
      access.installer = true;
    }

    return access;
  }

  checkAccessAll(component, requiredAccess: RoleType) {
    //Checks if user can see
    let permissions = this.getPermission();

    let hasAccess = false;

    for (let permission of permissions) {
      //Check admin
      let token = this.tokenService.getToken();
      if (token && typeof parseInt(token.UserType) == 'number') {
        if ((parseInt(token.UserType) & UserType.Admin) == UserType.Admin) {
          return true;
        }
      }

      //Check operator access
      if (permission.OperatesStation) {
        return true;
        //FIX: Now this allows all operators. Need to check the component. But that is different naming than what is used today
        //((permission.OperatorAccess & OperatorRoles.BusinessReports) == OperatorRoles.BusinessReports)
      }

      //Check if location owner
      if (permission.OwnsTheStation) return true; //permission.AccessLevel==='Owner'

      //Get users access to the component
      let access = new Access();

      //Check permission
      if (permission && permission.PermissionAssociated) {
        access = new Access(permission.PermissionAssociated[component]);
      }

      //Checking if user has the required access
      if (requiredAccess === 'Allowed' && access.canSee) hasAccess = true;
      if (requiredAccess === 'CanModify' && access.canEdit) hasAccess = true;
      if (requiredAccess === 'CanExport' && access.canExport) hasAccess = true;
      if (requiredAccess === 'CanDelete' && access.canDelete) hasAccess = true;
    }
    return hasAccess;
  }
  highLevelPermissions(accessLevel) {
    return {
      Analytics: 'Allowed',
      BoxMessages: 'Allowed',
      BoxSettings: 'NotAllowed',
      BusinessReports: 'Allowed',
      CommandsOnBoxes: 'NotAllowed',
      OrderHistories: 'NotAllowed',
      Rules: 'Allowed',
      StationInformations: 'Allowed',
      StationLimitations: 'NotAllowed',
      UserReports: 'NotAllowed'
    };
  }
  getPermission() {
    //Permissions are for modifying the view. Access is controlled backend

    let accessEnc = localStorage.getItem('ac');

    if (!accessEnc) {
      return [];
    }
    let key = localStorage.getItem('rToken');

    try {
      let bytes = AES.decrypt(accessEnc.toString(), key);

      return JSON.parse(bytes.toString(Utf8));
    } catch {
      this.authService.logout(true);
      return [];
    }
  }
}
export class Access {
  canEdit: boolean = false;
  canSee: boolean = false;
  canDelete: boolean = false;
  canExport: boolean = false;
  stationOwner: boolean = false;
  installer: boolean = false;
  operator: boolean = false;
  constructor(type: string = '') {
    //if(!type || type === "Not Allowed") do nothing
    if (type && type !== 'NotAllowed') {
      if (type === 'Allowed') {
        this.canSee = true;
      } else if (type === 'CanExport') {
        this.canSee = true;
        this.canExport = true;
      } else if (type === 'CanModify') {
        this.canSee = true;
        this.canExport = true;
        this.canEdit = true;
      } else if (type === 'CanDelete') {
        this.canSee = true;
        this.canEdit = true;
        this.canExport = true;
        this.canDelete = true;
      } else if (type === 'StationOwner') {
        this.canSee = true;
        this.canEdit = true;
        this.canExport = true;
        this.canDelete = true;
        this.stationOwner = true;
      }
    }
  }
}

/**
 * High level access to components used for AuthGuard.
 * NOTE: Does not need to contain usertypes that have been given permissions or is an owner
 */
