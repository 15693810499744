import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ChargingLimitSources, ChargingProfilePurposes } from "src/app/classes/ChargingProfile";

@Component({
  selector: 'sc-get-charging-profile',
  templateUrl: './get-charging-profile.component.html',
  styleUrls: ['./get-charging-profile.component.scss']
})
export class GetChargingProfileComponent implements OnInit {

  form: FormGroup;
  ids: FormArray;
  purposes = ChargingProfilePurposes;
  limitSources = ChargingLimitSources;


  constructor(public dialogRef: MatDialogRef<GetChargingProfileComponent>) { }

  ngOnInit() {
    this.form = new FormGroup({
      evseID: new FormControl(),
      chargingProfilePurpose: new FormControl(),
      stackLevel: new FormControl(),
      chargingProfileId: new FormArray([this.createFormItem()]),
      chargingLimitSource: new FormControl()
    })
    this.ids = this.form.get('chargingProfileId') as FormArray;
  }

  createFormItem() {
    return new FormGroup({
      ID: new FormControl(null),
    });

  }

  addInput() {
    this.ids.push(this.createFormItem());
  }
  deleteInput(index: number) {
    this.ids.removeAt(index);
  }

  handleOnSubmit(event) {
    event.preventDefault();
    if (this.form.valid) {
      const idValues = this.form.value.chargingProfileId.filter(((item) => item.ID !== null ));
      this.dialogRef.close({...this.form.value, chargingProfileId: idValues});
    }
  }

  close() {
    this.dialogRef.close();
  }
}
