import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { SearchModule } from '../../search/search-module.module';
import { SuperSearchDialogComponent } from './super-search-dialog/super-search-dialog.component';
import { SuperSearchComponent } from './super-search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTooltipModule,
    DirectivesModule,
    SearchModule
  ],
  declarations: [SuperSearchComponent, SuperSearchDialogComponent],
  exports: [SuperSearchComponent]
})
export class SuperSearchModule {}
