import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ComponentCriteriaTypes, ConfigVariable, Ocpp201Components, Ocpp201Variables } from "src/app/lib/commands";

@Component({
  selector: 'sc-set-display-message',
  templateUrl: './set-display-message.component.html',
  styleUrls: ['./set-display-message.component.scss']
})
export class SetDisplayMessageComponent implements OnInit {

  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<SetDisplayMessageComponent>) { }

  ngOnInit() {
    this.form = new FormGroup({
      id: new FormControl(null, [Validators.required]),
      priority: new FormControl(null, [Validators.required]),
      state: new FormControl(null),
      startDateTime: new FormControl(null),
      endDateTime: new FormControl(null),
      transactionId: new FormControl(null),
      format: new FormControl(null, [Validators.required]),
      language: new FormControl(null),
      content: new FormControl(null, [Validators.required]),
      DisplayComponentName: new FormControl(null),
      DisplayInstanceName: new FormControl(null),
      DisplayEVSEID: new FormControl(null),
      DisplayConnectorID: new FormControl(null),
    })
  }




  handleOnSubmit(event) {
    event.preventDefault();
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
