import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ComponentCriteriaTypes, ConfigVariable, Ocpp201Components, Ocpp201Variables } from "src/app/lib/commands";

@Component({
  selector: 'sc-clear-monitoring',
  templateUrl: './clear-monitoring.component.html',
  styleUrls: ['./clear-monitoring.component.scss']
})
export class ClearMonitoringComponent implements OnInit {

  form: FormGroup;
  inputs: FormArray;

  constructor(public dialogRef: MatDialogRef<ClearMonitoringComponent>) { }

  ngOnInit() {
    this.form = new FormGroup({
      inputs: new FormArray([this.createFormItem()])
    })
    this.inputs = this.form.get('inputs') as FormArray;
  }

  createFormItem() {
    return new FormGroup({
      ID: new FormControl('', [Validators.required]),
    });

  }





  addInput() {
    this.inputs.push(this.createFormItem());
  }
  deleteInput(index: number) {
    this.inputs.removeAt(index);
  }



  handleOnSubmit(event) {
    event.preventDefault();
    if (this.form.valid) {
      const values = this.form.value.inputs.map(((item) => {
        return item.ID;
      }))
      this.dialogRef.close({ IDs: values });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
