import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ComponentCriteriaTypes, ConfigVariable, Ocpp201Components, Ocpp201Variables } from "src/app/lib/commands";

@Component({
  selector: 'sc-get-all-display-messages',
  templateUrl: './get-all-display-messages.component.html',
  styleUrls: ['./get-all-display-messages.component.scss']
})
export class GetAllDisplayMessagesComponent implements OnInit {

  form: FormGroup;
  ids: FormArray;

  constructor(public dialogRef: MatDialogRef<GetAllDisplayMessagesComponent>) { }

  ngOnInit() {
    this.form = new FormGroup({
      priority: new FormControl(),
      state: new FormControl(),
      ids: new FormArray([this.createFormItem()])
    })
    this.ids = this.form.get('ids') as FormArray;
  }

  createFormItem() {
    return new FormGroup({
      ID: new FormControl(null),
    });

  }

  addInput() {
    this.ids.push(this.createFormItem());
  }
  deleteInput(index: number) {
    this.ids.removeAt(index);
  }

  handleOnSubmit(event) {
    event.preventDefault();
    if (this.form.valid) {
      const idValues = this.form.value.ids.filter(((item) => item.ID !== null ));
      this.dialogRef.close({...this.form.value, ids: idValues});
    }
  }

  close() {
    this.dialogRef.close();
  }
}
