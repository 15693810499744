<!-- NAVBAR -->
<mat-toolbar
  class="main-toolbar mat-elevation-z6 bg-primary txt-primary-contrast"
  *ngIf="menu.mode === 'over'">
  <button mat-icon-button (click)="sidenav.toggle()">
    <i class="material-icons app-toolbar-menu">menu</i>
  </button>
  {{ title }}
  <span class="toolbar-filler"></span>
  <button mat-icon-button class="search-button" (click)="toggleSearch()">
    <mat-icon>search</mat-icon>
  </button>
  <span class="toolbar-filler"></span>

  <button mat-button [matMenuTriggerFor]="dropdownMenu">
    <mat-icon class="example-icon">person</mat-icon>
  </button>
  <!--<button mat-icon-button (click)="goToSupport()" *ngIf="supportURL">
    <mat-icon>help_outline</mat-icon>
  </button>-->
</mat-toolbar>
<!-- end NAVBAR -->

<mat-sidenav-container
  class="main-sidenav-container"
  [ngClass]="{ 'small-screen': menu.mode === 'over' }">
  <mat-sidenav
    #sidenav
    [mode]="menu.mode"
    [opened]="menu.open"
    class="main-sidenav"
    [ngClass]="{ 'small-screen': menu.mode === 'over' }">
    <div class="content">
      <div class="header" *ngIf="menu.mode !== 'over'">
        <!--[style.background]="style.background" [style.color]="style.color"-->
        <a routerLink="/"><img [src]="style.img" [alt]="title" /></a>
        <!--<h2 *ngIf="style.text">{{ style.text }}</h2>-->

        <a [matMenuTriggerFor]="dropdownMenu">
          <p>{{ user.Email }}</p>
        </a>

        <button mat-icon-button class="search-button" (click)="toggleSearch()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <nav *ngFor="let menuItem of menuItems">
        <span>
          <span *ngIf="!menuItem.component || access[menuItem.component]">
            <h3 class="nav-header mt-2" *ngIf="menuItem.title">
              {{ menuItem.title | translate }}
            </h3>
            <ul>
              <span *ngFor="let page of menuItem.pages">
                <span>
                  <li *ngIf="!page.component || access[page.component]">
                    <a
                      routerLink="{{ page.url }}"
                      (click)="menuClick()"
                      routerLinkActive="active"
                      class="flex-center"
                      [routerLinkActiveOptions]="{ exact: page.exactMatch }"
                      [state]="{ resetFilters: true }">
                      <span
                        class="item-inner flex-center"
                        [ngClass]="{ 'with-icon': page.icon }">
                        <mat-icon *ngIf="page.icon">{{ page.icon }}</mat-icon>
                        <span>{{ page.title | translate }}</span>
                      </span>
                    </a>
                  </li>
                </span>
              </span>
            </ul>
          </span>
        </span>
      </nav>
      <div class="footer">
        <div class="updates">
          <div id="headway-badge"></div>
          <div><mat-icon>notifications_none</mat-icon></div>
          <div>Updates</div>
        </div>
        <!--<div class="bell-button">
          <svg
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
            class="style-scope yt-icon">
            <g class="style-scope yt-icon">
              <path
                d="M10,20h4c0,1.1-0.9,2-2,2S10,21.1,10,20z M20,17.35V19H4v-1.65l2-1.88v-5.15c0-2.92,1.56-5.22,4-5.98V3.96 c0-1.42,1.49-2.5,2.99-1.76C13.64,2.52,14,3.23,14,3.96l0,0.39c2.44,0.75,4,3.06,4,5.98v5.15L20,17.35z M19,17.77l-2-1.88v-5.47 c0-2.47-1.19-4.36-3.13-5.1c-1.26-0.53-2.64-0.5-3.84,0.03C8.15,6.11,7,7.99,7,10.42v5.47l-2,1.88V18h14V17.77z"
                class="style-scope yt-icon"></path>
            </g>
          </svg>
        </div>-->
        <!-- Hide for now -->

        <p class="bit-smaller">
          <span>{{ 'VERSION' | translate }} {{ version }}</span>
        </p>
        <div *ngIf="showEnv">
          <p class="bit-smaller environment-label">Environment: {{ env }}</p>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-toolbar class="auth-as-toolbar" *ngIf="loggedInAs">
    <span>{{ 'LOGGED_IN_AS' | translate }}: {{ user.Email }}</span>
    <button
      mat-raised-button
      class="margin-left"
      color="warn"
      (click)="logoutAs()">
      {{ 'LOGOUT' | translate }}
    </button>
  </mat-toolbar>

  <mat-toolbar
    class="auth-as-toolbar"
    *ngIf="
      companyService.company &&
      companyService.company.OwnedByLoggedInUser &&
      !companyService.company.ApprovedByCustomer &&
      ownsStation
    ">
    <span class="margin-right">
      {{ 'PLEASE_VALIDATE_BILLING_INFORMATION' | translate }}
    </span>
    <a
      mat-raised-button
      routerLink="/billing/settings"
      class="margin-left"
      *ngIf="notInBilling()">
      {{ 'GO_TO_BILLING_INFORMATION' | translate }}
    </a>
  </mat-toolbar>
  <router-outlet></router-outlet>
</mat-sidenav-container>

<mat-menu #dropdownMenu="matMenu">
  <a mat-menu-item routerLink="/settings">
    <mat-icon>settings</mat-icon>
    <span>{{ 'SETTINGS' | translate }}</span>
  </a>
  <button mat-menu-item (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{ 'LOG_OUT' | translate }}</span>
  </button>
</mat-menu>
