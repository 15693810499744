import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Component } from '@angular/core';
import { RouterService } from './services/router/router.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WhitelabelService } from './services/whitelabel/whitelabel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loaderImage: string = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private routerService: RouterService,
    private translate: TranslateService,
    private whitelabelService: WhitelabelService
  ) {
    this.translate.setDefaultLang('en');
    const userPref = localStorage.getItem('userPreferences');
    if (userPref) {
      this.translate.use(JSON.parse(userPref)?.language || 'en');
    }
  }
  ngOnInit() {
    this.setWhitelabel();
    //FIX: Do some check here to see if version is correct and that we are able to init the view?
    this.routerService.init();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event) =>
        this.titleService.setTitle(
          event['title'] +
            ' - ' +
            (this.whitelabelService.whitelabel.title || 'CURRENT')
        )
      );
  }
  setWhitelabel() {
    let whitelabelImg: any =
      this.whitelabelService.whitelabel.images.loading.img;
    this.loaderImage = whitelabelImg
      ? whitelabelImg
      : 'assets/img/current_logo.svg';

    //Use the code below to set the whitelabel favicon
    let whitelabelIcon: any =
      this.whitelabelService.whitelabel.images.favIcon?.img;
    let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    // favIcon.href = 'assets/img/current_logo.svg'; //Change with whitelabel.images.icon.img
    favIcon.href = whitelabelIcon ? whitelabelIcon : 'assets/img/current_c.svg';
  }
}
