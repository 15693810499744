import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { AppChangelogComponent } from './app-changelog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    FormsModule,
    DirectivesModule,
    SharedModule
  ],
  declarations: [AppChangelogComponent],
  exports: [AppChangelogComponent]
})
export class AppChangelogModule {}
