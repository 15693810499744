import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { reducers } from '.';

import { CompaniesEffects } from './companies/companies.effects';
import { CountriesEffects } from './countries/countries.effects';
import { FinancialsEffects } from './financials/financials.effects';
import { CustomerEffects } from './customer/customer.effects';
import { OperatorPackageEffects } from './operator-package/operator-package.effects';
import { OperatorEffects } from './operator/operator.effects';
import { UserEffects } from './user/user.effects';
import { InsightsEffects } from './insights/insights.effects';
import { RoamingPlatformEffects } from './roaming-platform/roaming-platform.effects';
import { ManufacturersEffects } from './manufacturers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({ maxAge: 10 }),
    EffectsModule.forRoot([
      CompaniesEffects,
      CountriesEffects,
      OperatorPackageEffects,
      OperatorEffects,
      UserEffects,
      FinancialsEffects,
      CustomerEffects,
      InsightsEffects,
      RoamingPlatformEffects,
      ManufacturersEffects
    ])
  ],
  declarations: []
})
export class StateModule {}
