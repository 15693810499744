import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Operator, OperatorUser } from 'src/app/classes/Operator';
import { DialogService } from '../dialog/dialog.service';
import { OperatorPackage } from 'src/app/classes/OperatorPackage';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {
  constructor(
    private _http: HttpInterceptorService,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private translate: TranslateService
  ) {}

  getOperatorsCanSee(): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('Operators/OperatorsICanSee').subscribe(
        (res) => {
          if (res.success) {
            resolve(res.datas);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve([]);
          }
        },
        (err) => {
          this.snackBar.open(
            this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            }),
            this.translate.instant('CLOSE')
          );
          resolve([]);
        }
      );
    });
  }
  // @TODO: Feels like an antiparten to write it this way;
  getSingle(operatorID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('Operators/' + operatorID).subscribe(
        (res) => {
          if (res.success) {
            resolve(res.datas);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  deactivate(operatorID) {
    return this._http.patch(`Operators/Activate/${operatorID}/false`, {});
  }
  getUsers(operatorID): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('Operators/GetAllUsers/' + operatorID).subscribe(
        (res) => {
          if (res.success) {
            resolve(res.datas);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve([]);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve([]);
        }
      );
    });
  }
  add(operator: Operator, operatorPackage: OperatorPackage): Promise<any> {
    return new Promise((resolve) => {
      operator.isActive = true;
      this._http
        .post('Operators', { Operator: operator, Package: operatorPackage })
        .subscribe(
          (res) => {
            if (res.success) {
              resolve(res.datas);
            } else {
              this.snackBar.open(
                this.translate.instant('SOMETHING_WENT_WRONG'),
                this.translate.instant('CLOSE')
              );
              resolve(false);
            }
          },
          (err) => {
            this.snackBar.open(
              `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
                errorName: err.errorName
              })}`,
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        );
    });
  }
  addUser(operatorUser: OperatorUser): Promise<any> {
    return new Promise((resolve) => {
      this._http.post('Operators/AddUserAccess', operatorUser).subscribe(
        (res) => {
          if (res.success) {
            resolve(true);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  updateUser(operatorUser: OperatorUser): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('Operators/ChangeUserAccess', operatorUser).subscribe(
        (res) => {
          if (res.success) {
            resolve(true);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
  deleteUser(operatorUser: OperatorUser): Promise<any> {
    return new Promise((resolve) => {
      this.dialog
        .prompt(
          this.translate.instant('CONFIRM_REMOVE_USER_OPERATOR'),
          null,
          this.translate.instant('REMOVE')
        )
        .subscribe((doDelete) => {
          if (doDelete) {
            this._http
              .delete(
                'Operators/RemoveUserAccess/' +
                  operatorUser.FK_UserID +
                  '/' +
                  operatorUser.FK_OperatorID
              )
              .subscribe(
                (res) => {
                  if (res.success) {
                    resolve(true);
                  } else {
                    this.snackBar.open(
                      this.translate.instant('SOMETHING_WENT_WRONG'),
                      this.translate.instant('CLOSE')
                    );
                    resolve(false);
                  }
                },
                (err) => {
                  this.snackBar.open(
                    `${this.translate.instant(
                      'SOMETHING_WENT_WRONG_WITH_ERROR',
                      { errorName: err.errorName }
                    )}`,
                    this.translate.instant('CLOSE')
                  );
                  resolve(false);
                }
              );
          } else {
            resolve(false);
          }
        });
    });
  }
  saveOperator(operator: Operator): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('Operators', operator).subscribe(
        (res) => {
          if (res.success) {
            resolve(true);
          } else {
            this.snackBar.open(
              this.translate.instant('SOMETHING_WENT_WRONG'),
              this.translate.instant('CLOSE')
            );
            resolve(false);
          }
        },
        (err) => {
          this.snackBar.open(
            `${this.translate.instant('SOMETHING_WENT_WRONG_WITH_ERROR', {
              errorName: err.errorName
            })}`,
            this.translate.instant('CLOSE')
          );
          resolve(false);
        }
      );
    });
  }
}
