import { Injectable } from '@angular/core';
import { HttpInterceptorService } from '../../services';

import { YearMonthDateKey } from '.';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { snackbarConfig } from 'src/app/defaults';
import { ExportService } from 'src/app/services/export/export.service';
// Should be moved to somewhere globally accessible. Perhaps the lib/api
@Injectable({
  providedIn: 'root'
})
export class FinancialsService {
  constructor(
    private _http: HttpInterceptorService,
    private snackBar: MatSnackBar,
    private exportService: ExportService
  ) {}

  getFinancialsForCompany(
    PK_CompanyID: number,
    fromDateTimestamp: number,
    toDateTimestamp: number
  ) {
    return this._http.get(
      `Revenues/FinanceReportForCompany/${PK_CompanyID}/${fromDateTimestamp}/${toDateTimestamp}`
    );
  }

  getOrdersForCompany(
    PK_CompanyID: number,
    stationID: number,
    startDate: YearMonthDateKey,
    endDate: YearMonthDateKey,
    currency: string
  ) {
    return this._http.post(`Orders/OrdersLinkedToCompany`, {
      CompanyId: PK_CompanyID,
      Currency: currency,
      Start: startDate,
      End: endDate,
      StationID: stationID
    });
  }

  downloadOrderLines = (
    companyID: number,
    stationID: number,
    startDate: YearMonthDateKey,
    endDate: YearMonthDateKey,
    currency: string,
    exportType?: string
  ) => {
    this.snackBar.open(
      'Exporting data, this may take a few seconds...',
      'Close'
    );

    this.getOrdersForCompany(
      companyID,
      stationID,
      startDate,
      endDate,
      currency
    ).subscribe({
      next: (data) => {
        if (data?.Orders?.length) {
          this.snackBar.dismiss();
          switch (exportType) {
            case 'CSV':
              this.exportService.exportCSV(
                data.Orders,
                `Orders_${startDate.Month}_${startDate.Year}-${endDate.Month}_${endDate.Year}`
              );
              break;
            case 'JSON':
              this.exportService.exportJSON(
                data.Orders,
                `Orders_${startDate.Month}_${startDate.Year}-${endDate.Month}_${endDate.Year}`
              );
              break;
            case 'Excel':
            default:
              this.exportService.simpleExcelExport(
                data.Orders,
                `Orders_${startDate.Month}_${startDate.Year}-${endDate.Month}_${endDate.Year}`,
                [
                  'Date',
                  'DatePaidOut',
                  'InvoiceDateIssued',
                  'InvoiceDatePaid',
                  'TimeFinished',
                  'TimeStarted'
                ]
              );
          }
          this.snackBar.dismiss();
        } else {
          this.snackBar.open('No orders', 'Close', snackbarConfig);
        }
      },
      error: (err) => {
        this.snackBar.open('Error exporting data', 'Close', snackbarConfig);
      }
    });
  };

  simpleExport = (data: any[], name: string) => {
    this.exportService.simpleExcelExport(data, name);
  };

  exportJSON = (data: any[], name: string) => {
    this.exportService.exportJSON(data, name);
  };
}
