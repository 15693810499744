<form (submit)="handleOnSubmit($event)" [formGroup]="configForm">
  <h2 mat-dialog-title>{{ title || 'CONFIGURATION' | translate }}</h2>

  <div mat-dialog-content class="dialog-content">
    <!-- <sc-ocpp201-component-list
    [listenChanges]="true"
    [showControllerInput]="showControllerInput"
    [showVariableInput]="showVariableInput"
    [showValueInput]="showValueInput"
    [showAttributeTypeInput]="showAttributeTypeInput"
    [showInstanceInput]="showInstanceInput"
    [showESVEIDInput]="showESVEIDInput"
    (onListChange)="onListChange($event)"
    [useDialogSubmit]="true"></sc-ocpp201-component-list> -->

    <div *ngIf="showComponentCriteria">
      <mat-form-field>
        <mat-label>Component criterion</mat-label>
        <mat-select formControlName="ComponentCriteria" multiple>
          <mat-select-trigger>
            {{ configForm.controls.ComponentCriteria.value?.join(',') || '' }}
          </mat-select-trigger>
          <mat-option *ngFor="let item of ComponentCriteriaList" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div formArrayName="Configurations">
      <div
        class="mb-4 flex"
        *ngFor="let item of Configurations.controls; let i = index"
        [formGroupName]="i">
        <ng-container *ngIf="ocppVersion === ocppEnum.VERSION_201_JSON">

          <mat-form-field floatLabel="auto" *ngIf="!hideControllerInput">
            <input
              type="text"
              matInput
              (focus)="filterComponents($event)"
              (input)="filterComponents($event)"
              formControlName="Controller"
              [matAutocomplete]="autoComponents"
              placeholder="Controller" />
            <mat-autocomplete #autoComponents="matAutocomplete">
              <mat-option *ngFor="let item of componentsList" [value]="item">
                {{ item }}
              </mat-option>
            </mat-autocomplete>
            <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field
            floatLabel="auto"
            class="ml-4"
            *ngIf="!hideVariableInput">
            <input
              type="text"
              matInput
              (focus)="filteVariable($event)"
              (input)="filteVariable($event)"
              formControlName="Key"
              [matAutocomplete]="autoVariables"
              placeholder="Key" />
            <mat-autocomplete #autoVariables="matAutocomplete">
              <mat-option *ngFor="let item of variablesList" [value]="item">
                {{ item }}
              </mat-option>
            </mat-autocomplete>
            <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field floatLabel="auto" class="ml-4" *ngIf="!hideValueInput">
            <input
              matInput
              placeholder="Value"
              required
              formControlName="Value" />
            <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field
            floatLabel="auto"
            class="ml-4"
            *ngIf="!hideAttributeTypeInput">
            <mat-select
              placeholder="Attribute Type"
              formControlName="AttributeType">
              <mat-option></mat-option>
              <mat-option value="Actual">Actual</mat-option>
              <mat-option value="Target">Target</mat-option>
              <mat-option value="MinSet">MinSet</mat-option>
              <mat-option value="MaxSet">MaxSet</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            floatLabel="auto"
            class="ml-4"
            *ngIf="!hideInstanceInput">
            <input matInput placeholder="Instance" formControlName="Instance" />
          </mat-form-field>

          <mat-form-field floatLabel="auto" class="ml-4" *ngIf="!hideESVEIDInput">
            <input
              matInput
              placeholder="EVSE ID"
              formControlName="EVSEID"
              type="number" />
          </mat-form-field>
        </ng-container>

        <ng-container  *ngIf="ocppVersion === ocppEnum.VERSION_16_JSON">
          <mat-form-field floatLabel="auto">
            <input
              matInput
              placeholder="Key"
              required
              formControlName="Key" />
            <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field floatLabel="auto" class="ml-4"  *ngIf="!hideValueInput">
            <input
              matInput
              placeholder="Value"
              required
              formControlName="Value" />
            <mat-error>{{ 'REQUIRED' | translate }}</mat-error>
          </mat-form-field>
        </ng-container>

        <button
          mat-icon-button
          class="ml-4"
          (click)="deleteComponentValue(i)"
          *ngIf="Configurations.length > 1">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <div class="mb-4">
      <button mat-raised-button (click)="addComponentValue()">
        <mat-icon>add</mat-icon>
        {{ 'ADD' | translate }}
      </button>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ action || ('OK' | translate) }}
    </button>
    <button mat-raised-button (click)="close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
