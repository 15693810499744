import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TermsService } from 'src/app/services/terms/terms.service';

@Component({
  selector: 'sc-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  termsAccepted: boolean = false;
  newTerms: any;
  termsURL: string;
  userID: any;
  constructor(
    private termsService: TermsService,
    private dialogRef: MatDialogRef<TermsAndConditionsComponent>
  ) {}

  ngOnInit() {}
  closeTerms() {
    if (!this.termsAccepted) return;
    //FIX: Update the terms
    this.termsService.acceptTerms(this.userID, true).then((res) => {
      this.dialogRef.close();
    });
  }
}
