import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'sc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  public message: string; //Req
  public placeholder: string; //Req
  public hint: string = '';
  public content: string;
  public action: string;
  public preset: string;
  public textarea: boolean = false;
  public optional: boolean = false;
  inputCtrl: FormControl; // = new FormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<InputComponent>) {}

  ngOnInit() {
    this.inputCtrl = new FormControl('', [Validators.required]);
    if (this.optional) this.inputCtrl.clearValidators();
    if (this.preset) {
      this.inputCtrl.patchValue(this.preset);
    }
  }
  uppercaseFirst(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  submitForm() {
    if (this.inputCtrl.invalid) return;

    if (this.optional) {
      this.dialogRef.close({ value: this.inputCtrl.value });
    } else {
      this.dialogRef.close(this.inputCtrl.value);
    }
  }
}
