<form (submit)="handleOnSubmit($event)" [formGroup]="form">
  <h2 mat-dialog-title>{{ 'GET_CHARGING_PROFILE' | translate }}</h2>
  <div class="input-row">
    <mat-form-field floatLabel="auto">
      <input
        matInput
        placeholder="EVSE ID"
        formControlName="evseID"
        type="number" />
    </mat-form-field>
  </div>
  <div class="input-row">
    <mat-form-field>
      <mat-label>{{ 'CHARGING_PROFILE_PURPOSE' | translate }}</mat-label>
      <mat-select formControlName="chargingProfilePurpose">
        <mat-option *ngFor="let item of purposes" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="input-row">
    <mat-form-field>
      <mat-label>{{ 'CHARGING_LIMIT_SOURCE' | translate }}</mat-label>
      <mat-select formControlName="chargingLimitSource" multiple="true">
        <mat-option *ngFor="let item of limitSources" [value]="item">
          {{ item }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="input-row">
    <mat-form-field floatLabel="auto">
      <input
        matInput
        [placeholder]="'STACK_LEVEL' | translate"
        formControlName="stackLevel"
        type="number" />
    </mat-form-field>
  </div>

  <div formArrayName="chargingProfileId">
    <div
      class="mb-4 flex"
      *ngFor="let item of ids.controls; let i = index"
      [formGroupName]="i">
      <mat-form-field floatLabel="auto" class="mr-4">
        <input
          matInput
          [placeholder]="'CHARGING_PROFILE_ID' | translate"
          formControlName="ID"
          type="number" />
      </mat-form-field>

      <button
        mat-icon-button
        class="ml-4"
        (click)="deleteInput(i)"
        type="button">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <div class="mb-4">
    <button mat-button (click)="addInput()" type="button">
      <mat-icon>add</mat-icon>
      {{ 'ADD' | translate }}
    </button>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" type="submit">
      {{ 'GET' | translate }}
    </button>
    <button mat-raised-button (click)="close()" type="button">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
